import React from 'react';
import './HomeContentMain.css';
import {FaPumpMedical} from 'react-icons/fa';

function HomeContentMain() {
    return (
        <div className="hero-section">
            <div className='text_wrapper'>
                <div className='text-area'>

                    <h2 className="slogan">Providing exceptional patient care with top-tier medical equipment.</h2>
                    <h1 className="company-name">Lengoati Med Trading</h1>
                    <FaPumpMedical style={{ color: '#1B0273', fontSize: '10rem'}} />
                </div>
            </div>
        </div>
    );
}

export default HomeContentMain;

export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Leading the way in medical supply distribution.',
  headline: 'LENGOATI MED TRADING',
  description:
    'Lengoati Med Trading is a privately-owned company operating out of Johannesburg, South Africa. We supply a large range of top quality medical products across the country',
  buttonLabel: 'More Info',
  imgStart: 'start',
  img: 'images/logo6.jpg',
  alt: 'Credit Card',
  showButton: true,
  url: '/services'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'PRODUCTS AND SERVICES',
  headline: 'MEDICAL DISTRIBUTION',
  description:
    "Lengoati Med Trading's product mix currently consists of a variety of different products for all medical and surgical needs, consisting of surgical consumables and specialty products which are used on a daily basis",
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'images/logo2.jpg',
  alt: 'Vault',
  showButton: false
  
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Who Are We?',
  headline: 'ABOUT',
  description:
    "Lengoati Med Trading is a company which has recently been founded. Lengoati Med Trading Distributors' customer base is extensive, throughout Africa. Our main field of expertise lies in the supply of medical goods to institutions and patients as well as the supply of medicine to registered vendors only.",
  buttonLabel: 'Contact Us',
  imgStart: 'end',
  img: 'images/logo5.jpg',
  alt: 'Vault',
  showButton: true,
  url: '/contact-us'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};

export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'SERVICES',
  headline: 'LENGOATI MED TRADING SERVICES OFFERED',
  description:
    'Lengoati Med Trading is a proudly South African company focusing on the sales and distribution of Medical Furniture and Hospital Equipment. The business was built on medical expertise, excellent client service and the pursuit of medical- and clinical innovation. Today, this desire to constantly improve and grow is an integral part of the Lengoati Med Trading culture and the brand is now firmly established in the South African Medical supply chain.',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'images/logo4.jpg',
  alt: 'Credit Card'
};

export const homeObjSix = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'WANT TO GET IN CONTACT? BELOW ARE OUR DETAILS.',
  headline: 'CONTACT INFORMATION',
  description:
    'We provide local shipping. If there are any questions feel free to get in contact with us.',
  buttonLabel: 'Shop Now',
  imgStart: '',
  img: 'images/logo3.jpg',
  alt: 'Credit Card',
  email: 'Tuvsurgical@gmail.com',
  contactName: 'Tumelo Vincent Selowa',
  contactNumber: '063 634 2156',
  Addressln1: '14 8th Avenue',
  Addressln2: 'Northmead',
  Addressln3: 'Benoni',
};
export const homeObjOneCon = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'WANT TO GET IN CONTACT? BELOW ARE OUR DETAILS.',
    headline: 'CONTACT INFORMATION',
    description:
      'We provide local shipping. If there are any questions feel free to get in contact with us.',
    buttonLabel: 'Shop Now',
    imgStart: 'start',
    img: 'images/logo3.jpg',
    alt: 'Credit Card',
    email: 'lengoatimed@gmail.com',
    contactName: 'Gift Lengoati',
    contactNumber: '068 589 7814',
    Addressln1: '7 North Reef Road',
    Addressln2: 'Elandsfortein',
    Addressln3: 'Germiston',
  };
import React from 'react';
import './Footer.css';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {FaBookMedical} from 'react-icons/fa';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
        Lengoati Med Trading is in the process of being registered for SAHPRA
        </p>

      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>EMAIL ADDRESS</h2>
            <Link to='/'>lengoatimed@gmail.com</Link>

          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>DIRECTOR</h2>
            <Link to='/services'>Gift Lengoati</Link>

          </div>
          <div className='footer-link-items'>
            <h2>Contact Number</h2>
            <Link to='/contact-us'>068 589 7814</Link>

          </div>
          
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>

          </div>
          <small className='website-rights'>Lengoati Med Trading © 2023</small>

        </div>
      </section>
    </div>
  );
}

export default Footer;

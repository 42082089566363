import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour, homeObjFive, homeObjSix } from './Data';
import Pricing from '../../Pricing';
import HomeContentMain from '../../HomeContentSection/HomeContentMain';
import '../../HomeContentSection/HomeContentMain.css'
import IconPackDisplay from '../../IconPack/IconPackDisplay';
import ContactUsMainDisp from '../ContactUs/ContactUsMainDisp';

function Home() {
  return (
    <>
      <HomeContentMain/>
      <HeroSection {...homeObjOne} />
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjTwo} />


      
      <ContactUsMainDisp/>
      
     
    </>
  );
}

export default Home;
